import React from "react";
import "./index.css";
import { getDate, getFullDate } from "../../../../utils/Helper";
import useCustomization from "../../../../context/CustomizationContext";

const SenderRowComponent = (message) => {
  let { customization } = useCustomization();
  let msg = message?.msg;
  return (
    <div className="sender_msg">
      {msg?.voice_path ? (
        <div className="sender_msg_row_audio">
          <audio src={msg?.voice_path} controls></audio>
          <div className="timestamp_div">
            <p title={getFullDate(msg?.timestamp)}>{getDate(msg?.timestamp)}</p>
          </div>
        </div>
      ) : (
        <div
          className="sender_msg_row"
          style={{
            background: customization?.visitor_msg_background_color,
            color: customization?.font_color,
          }}
        >
          <p className="msg_div">{msg?.text}</p>

          <div className="timestamp_div">
            <p
              style={{ color: customization?.font_color }}
              title={getFullDate(msg?.timestamp)}
            >
              {getDate(msg?.timestamp)}
            </p>
          </div>
        </div>
      )}

      <br />
    </div>
  );
};

export default React.memo(SenderRowComponent);
