import Welcome from "../pages/Welcome";
import Feedback from "../pages/Feedback";
import Layout from "../Layout";
import Thankyou from "../pages/Thankyou";
import Chat from "../pages/Chat";
import ConversationList from "../pages/ConversationList";
import PrechatForm from "../pages/PrechatForm";

export const routes = [
  { key: "welcome", path: "/", element: <Welcome /> },
  { key: "pre-chat-form", path: "/pre-chat-form", element: <PrechatForm /> },
  { key: "conv-list", path: "/conv-list", element: <ConversationList /> },
  { key: "chat", path: "/chat", element: <Chat /> },
  { key: "thank-you", path: "/thank-you", element: <Thankyou /> },
  { key: "feedback", path: "/feedback", element: <Feedback /> },
];

export const rootRoute = [{ key: "layout", path: "/*", element: <Layout /> }];
