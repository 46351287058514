import React, { useState, createContext, useContext } from "react";
import { core_api } from "../../services/core_api";

let defaultParams = {
  user_id: undefined,
  project_id: undefined,
  bot_id: undefined,
  identifier: undefined,
  visitor_id: undefined,
  session_id: undefined,
};

const initialState = {
  convParams: defaultParams,
  setConvParams: () => {},
};

const ProjectParams = createContext(initialState);

export const ParamsProvider = ({ children }) => {
  const [convParams, setConvParams] = useState(initialState.convParams);

  const setProjectParams = async (params) => {
    setConvParams((prev) => ({
      ...prev,
      user_id: params?.user_id,
      project_id: params?.project_id,
      bot_id: params?.bot_id,
      identifier: params?.identifier,
    }));
  };

  const setSpecificParam = async (name, value) => {
    setConvParams((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <ProjectParams.Provider
      value={{
        convParams,
        setProjectParams,
        setSpecificParam,
      }}
    >
      <>{children}</>
    </ProjectParams.Provider>
  );
};

const useProjectParams = () => {
  const context = useContext(ProjectParams);

  if (context === undefined) {
    throw new Error("useAuth must be used within a ProjectParams");
  }
  return context;
};

export default useProjectParams;
