import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Input } from "antd";
import "../../../../assets/style/global.css";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { CHATBOT_MODELLING_API_URL } from "../../../../utils/URLS";
import { generateRandomString } from "../../../../utils/Helper";
import { getCookie } from "../../../../utils/storage";
import useProjectParams from "../../../../context/ProjectParamsContext";

const Send = (props) => {
  const navigate = useNavigate();
  const { convParams } = useProjectParams();

  const textareaRef = useRef(null);
  const [text, setText] = useState("");
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showTextBox, setShowTextBox] = useState(true);
  const {
    focus,
    setScrollHeight,
    scrollToBottom,
    setInputFocued,
    sendMessage,
    setEnd,
    appendString,
    setMessages,
    messages,
    end,
    sessionEnded,
  } = props;
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const toggleEmojiPanel = () => {
    setShowEmojiPanel(!showEmojiPanel);
    textareaRef.current.focus();
  };
  const handleTextareaFocus = () => {
    setInputFocued(true);
    textareaRef.current.focus();
    // markMessageRead();
  };
  const handleBlur = () => {
    setInputFocued(false);
    textareaRef.current.blur();
  };
  const onEmojiChange = (event, emojiObject) => {
    textareaRef.current.focus();
    setText((prevState) => {
      return prevState.concat(emojiObject.emoji);
    });
  };

  const handleFileInput = (file) => {
    setUploading(true);
  };

  const handleMessageSent = async () => {
    if (text?.trim()) {
      sendMessage({ msg: text });
      setText("");
    }
  };

  const handleOnFilePaste = (event) => {
    if (event.clipboardData.files.length > 0) {
      handleFileInput(event.clipboardData.files[0]);
    }
  };

  const myDivRef = useRef(null);
  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height } = entry.contentRect;
        setScrollHeight(`calc(100vh - 68px - ${height}px + -20px) `);
      }
    });

    // Observe the target element (myDivRef.current)
    if (myDivRef.current) {
      resizeObserver.observe(myDivRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (myDivRef.current) {
        resizeObserver.unobserve(myDivRef.current);
      }
    };
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSent();
    }
  };
  const postMessage = async (file) => {
    try {
      setEnd(true);

      let session_id = convParams?.session_id;
      let user_id = convParams?.user_id;
      let project_id = convParams?.project_id;
      let bot_id = convParams?.bot_id;

      let obj = {
        bot_id: bot_id,
        session_id: session_id,
        user_id: user_id,
        project_id: project_id,
      };

      let url = `${CHATBOT_MODELLING_API_URL}query?user_id=${obj?.user_id}&project_id=${obj?.project_id}&bot_id=${obj?.bot_id}&session_id=${obj?.session_id}`;
      let formData = new FormData();
      let audioFile = new File([file], `${generateRandomString(5)}.mp3`);

      formData.append("voice_note", audioFile);

      var response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "multipart/form-data",
        },
        body: formData,
      });
      var reader = response.body.getReader();
      var decoder = new TextDecoder("utf-8");
      async function processResult() {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          let token = decoder.decode(value);

          if (
            token.endsWith(".") ||
            token.endsWith("!") ||
            token.endsWith("?")
          ) {
            appendString(token);
          } else {
            appendString(token);
          }
        }
      }

      await processResult();
      setEnd(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const addAudioElement = async (blob) => {
    const url = URL.createObjectURL(blob);

    const newObject = {
      id: messages.length + 1,
      voice_path: url,
      timestamp: new Date(),
      isVisitor: true,
    };
    setMessages([...messages, newObject]);
    await postMessage(blob);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowTextBox(!recorderControls?.isRecording);
    }, 100);
  }, [recorderControls?.isRecording]);

  return (
    <div onPaste={handleOnFilePaste} className="container">
      <div className="send-box" ref={myDivRef}>
        <div className="flex_between send-icons">
          <div className="flex_items area-1">
            {showTextBox && (
              <div style={{ width: "100%", paddingTop: "5px" }}>
                <Input.TextArea
                  autoSize={{
                    minRows: 1,
                    maxRows: 5,
                  }}
                  disabled={end}
                  ref={textareaRef}
                  onFocus={handleTextareaFocus}
                  onBlur={handleBlur}
                  className={
                    focus ? "visitor-text-area active" : "visitor-text-area"
                  }
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  placeholder="Type Here..."
                  onKeyDown={handleKeyPress}
                  onClick={(e) => setShowEmojiPanel(false)}
                />
              </div>
            )}
          </div>
          <div className="flex_items ">
            <div className="flex_items area-2-btns">
              <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                showVisualizer={true}
              />
            </div>
            <div>
              <i
                onClick={handleMessageSent}
                className="fa-solid fa-paper-plane"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Send);
