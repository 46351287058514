import Cookies from "js-cookie";

// Set cookie
export const setCookie = (key, data) => {
  if (typeof data === "object") data = JSON.stringify(data);
  Cookies.set(key, data, { sameSite: "None", secure: true });
};

// Get Cookie
export const getCookie = (key) => {
  return Cookies.get(key);
};
