export const IFrameHelper = {
  sendMessage: (msg) => {
    window.parent.postMessage(`${JSON.stringify({ ...msg })}`, "*");
  },
};

export const getAgentInitials = (name, initial = false) => {
  if (initial) {
    return name
      .split(" ")
      .map((chunk) => chunk.charAt(0).toUpperCase())
      .join("");
  }
  return name;
};

export const getDate = (date) => {
  const dateObj = new Date(date);

  return dateObj?.toLocaleString([], {
    // weekday: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const getFullDate = (date) => {
  const dateObj = new Date(date);
  const formattedDate = dateObj?.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  return formattedDate;
};

export function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}
