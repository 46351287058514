import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { IFrameHelper } from "../../utils/Helper";
import { modelling_api } from "../../services/modelling_api";
import { Spin } from "antd";
import { getVisitor } from "../../utils/visitor";
import { getCookie, setCookie } from "../../utils/storage";
import useProjectParams from "../../context/ProjectParamsContext";

const ConversationList = (props) => {
  const navigate = useNavigate();
  const { setSpecificParam } = useProjectParams();

  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [convParams, setConvParams] = useState({
    user_id: undefined,
    project_id: undefined,
    bot_id: undefined,
    identifier: undefined,
  });

  const handleMinimize = () => {
    IFrameHelper.sendMessage({
      event: "minimizeChat",
    });
  };
  useEffect(() => {
    let user_id = getCookie("user_id");
    let project_id = getCookie("project_id");
    let bot_id = getCookie("bot_id");
    let identifier = getCookie("visitor_id");

    if (user_id && project_id && bot_id && identifier) {
      setConvParams({
        user_id,
        project_id,
        bot_id,
        identifier,
      });
    }
  }, []);

  const createConv = async (obj) => {
    try {
      let visitorObj = await getVisitor();
      let updatedObj = { ...visitorObj, ...obj };
      let response = await modelling_api.post(
        `new_conversation?user_id=${obj?.user_id}&project_id=${obj?.project_id}&bot_id=${obj?.bot_id}&visitor_id=${obj?.identifier}`,
        updatedObj
      );
      // setCookie("session_id", response?.data?.session_id);
      localStorage.setItem("session_id", response?.data?.session_id);
      setSpecificParam("session_id", response?.data?.session_id);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onClickSend = async () => {
    const sessionsLength = sessions?.length;
    const session_id = getCookie("session_id");
    const allSessionsEnded = sessions?.every(
      (obj) => obj.sessionEnded === true
    );
    const condition = sessionsLength === 0 || allSessionsEnded;
    if (condition) {
      await createConv(convParams);
      navigate(`/chat`);
    } else {
      navigate(`/chat`);
    }
  };

  const getConversations = async (userId, projectId, botId, identifier) => {
    setLoading(true);
    try {
      let response = await modelling_api.post(
        `list_conversations?user_id=${userId}&project_id=${projectId}&bot_id=${botId}&visitor_id=${identifier}`
      );

      let sessions = response?.data[0]?.sessions;
      setSessions(sessions?.reverse());
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      convParams?.user_id &&
      convParams?.project_id &&
      convParams?.bot_id &&
      convParams?.identifier
    ) {
      getConversations(
        convParams?.user_id,
        convParams?.project_id,
        convParams?.bot_id,
        convParams?.identifier
      );
    }
  }, [convParams]);

  useEffect(() => {
    // Add event listener to listen for messages from parent window
    window.addEventListener("message", handleMessageFromParent);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("message", handleMessageFromParent);
    };
  }, []);

  const handleMessageFromParent = (event) => {
    try {
      if (typeof event.data === "string") {
        const eventData = JSON.parse(event.data);
        if (eventData.event === "params") {
          if (
            convParams?.user_id &&
            convParams?.project_id &&
            convParams?.bot_id &&
            convParams?.identifier
          ) {
          } else {
            setCookie("project_id", eventData?.project_id);
            setCookie("bot_id", eventData?.bot_id);
            setCookie("user_id", eventData?.user_id);
            setCookie("visitor_id", eventData?.identifier);

            // localStorage.setItem("project_id", eventData?.project_id);
            // localStorage.setItem("bot_id", eventData?.bot_id);
            // localStorage.setItem("user_id", eventData?.user_id);
            // localStorage.setItem("visitor_id", eventData?.identifier);
            // let identifier = Cookies.get("cw_identifier");

            setConvParams({
              user_id: eventData?.user_id,
              project_id: eventData?.project_id,
              bot_id: eventData?.bot_id,
              identifier: eventData?.identifier,
            });
            getConversations(
              eventData?.user_id,
              eventData?.project_id,
              eventData?.bot_id,
              eventData?.identifier
            );
          }
        }
      }
    } catch (error) {
      console.error("Error parsing message data:", error);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="conv_cont">
        <div className="header_title">
          <h1>Messages</h1>
        </div>
        <div
          className=" minimize-btn"
          onClick={handleMinimize}
          title="Minimize"
        >
          {" "}
          <i className="fa-solid fa-minus "></i>
        </div>

        <div className="conv_list">
          {sessions?.map((session) => (
            <div
              key={session?.session_id}
              className="conv"
              onClick={() => {
                setCookie("session_id", session?.session_id);
                // localStorage.setItem("session_id", session?.session_id);
                navigate(`/chat`, {
                  state: { session: session },
                });
              }}
            >
              <div className="short_msg">
                <div className="agents_name">
                  <span className="last_msg">{session?.session_id}</span>
                </div>

                <span className="info">Chat - Today</span>
              </div>

              <div className="icon">
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          ))}
        </div>

        <div className="send_us_btn">
          <button onClick={onClickSend}>
            Send us a message
            <i className="fa-solid fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default ConversationList;
