import React, { useEffect, useRef } from "react";
import { getDate } from "../../../../utils/Helper";
import SenderRowComponent from "../SenderRowComponent";
import ReceiverRowComponent from "../ReceiverRowComponent";
import useCustomization from "../../../../context/CustomizationContext";

const MessageComponent = (props) => {
  const { messages, botMsgs, setMessages, end, setBotMsgs, answer } = props;

  let { customization } = useCustomization();
  let singleString = botMsgs.join("");
  const messageEnd = useRef();
  const scrollToBottom = () => {
    messageEnd.current?.scrollIntoView({ behavior: "smooth" });
  };

  const filteredMessages = messages?.slice();
  if (
    filteredMessages.length > 0 &&
    !filteredMessages[filteredMessages.length - 1].hasOwnProperty(
      "isVisitor"
    ) &&
    answer !== ""
  ) {
    filteredMessages.pop();
  }

  useEffect(() => {
    if (!end && singleString !== "") {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: messages.length + 1,
          text: singleString,
          timestamp: new Date(),
        },
      ]);
    } else {
      setBotMsgs([]);
    }
  }, [end]);
  const initial_msg = {
    text: customization?.initial_message ?? "Hello, How may I help you?",
    timestamp: new Date(),
  };
  return (
    <div>
      <div>
        <>
          <ReceiverRowComponent msg={initial_msg} />
          {scrollToBottom()}
        </>
        {filteredMessages?.map((msg, index) =>
          msg?.isVisitor ? (
            <>
              <SenderRowComponent msg={msg} />
              {scrollToBottom()}
            </>
          ) : (
            <>
              <ReceiverRowComponent msg={msg} botMsgs={botMsgs} />
              {scrollToBottom()}
            </>
          )
        )}
      </div>
      {scrollToBottom()}
      <>
        <div className="receiver_msg">
          <div
            className="receiver_msg_row"
            style={{
              backgroundColor: customization?.ai_msg_background_color,
              color: customization?.ai_font_color,
            }}
          >
            <div
              className="spotlight__answer"
              style={{
                backgroundColor: customization?.ai_msg_background_color,
                color: customization?.ai_font_color,
              }}
            >
              {answer && <p>{answer}</p>}
            </div>
            <div ref={messageEnd} />
          </div>
        </div>
      </>
    </div>
  );
};

export default MessageComponent;
