import Cookies from "js-cookie";

export const events = {
  getIdentifier: () => Cookies.get("cw_identifier"),
  getVisitor: () => Cookies.get("cw_vistor"),
  getSession: () => Cookies.get("cw_session"),
  getIpAddress: () => Cookies.get("ipAddress"),
  getName: () => Cookies.get("name"),
  getCountryName: () => Cookies.get("countryName"),
  getCountryCode: () => Cookies.get("countryCode"),
  getCity: () => Cookies.get("city"),
  getStateProv: () => Cookies.get("stateProv"),
  getUtmMedium: () => Cookies.get("utm_medium") || Cookies.get("medium"),
  getUtmSource: () =>
    Cookies.get("utm_src") ||
    Cookies.get("src") ||
    Cookies.get("source") ||
    Cookies.get("utm_source"),
  getGlickId: () => Cookies.get("gclid"),
  getMsclikId: () => Cookies.get("msclkid") || Cookies.get("MSCLKID"),
  getUtmCampaign: () => Cookies.get("utm_campaign") || Cookies.get("campaign"),
  getUtmTerm: () =>
    Cookies.get("utm_term") || Cookies.get("kw") || Cookies.get("keyword"),
  getUtmContent: () => Cookies.get("utm_content") || Cookies.get("content"),
  getPlatform: () => Cookies.get("platform"),
  getDevice: () => Cookies.get("device"),
  getChatStatus: () => Cookies.get("chatStatus"),
  getOS: () => Cookies.get("os"),
  getReferrer: () => Cookies.get("referrer"),
  getSessionStopped: () => Cookies.get("sessionStopped"),
};

export const getVisitor = async () => {
  const identifier = events.getIdentifier();
  const ipAddress = events.getIpAddress();
  const visitor = events.getVisitor();
  const countryName = events.getCountryName();
  const countryCode = events.getCountryCode();
  const city = events.getCity();
  const stateProv = events.getStateProv();

  const visitorObj = {
    name: events.getName() || "",
    visitor_default: `Visitor#${visitor}`,
    identifier,
    visitor,
    ipAddress,
    countryName,
    countryCode,
    city,
    stateProv,
    totalVisits: 0,
    landingPage: window.location.href,
    platform: events.getPlatform(),
    os: events.getOS(),
    device: events.getDevice(),
    currentPage: window.location.href,
    lastActivity: new Date(),
    referrer: events.getReferrer() || null,
    isBanned: false,
    created_at: new Date(),
  };

  return visitorObj;
  // await updateVisitor(identifier, visitorObj);
};
