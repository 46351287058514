import React, { useEffect, useState } from "react";
import { IFrameHelper, getAgentInitials } from "../../../../utils/Helper";
import { Popconfirm } from "antd";
import "../../../../assets/style/global.css";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Avatar, Tooltip } from "antd";
import { modelling_api } from "../../../../services/modelling_api";
import Cookies from "js-cookie";
import { getCookie } from "../../../../utils/storage";
import useCustomization from "../../../../context/CustomizationContext";
import useProjectParams from "../../../../context/ProjectParamsContext";

const Header = () => {
  const navigate = useNavigate();
  const { bot } = useCustomization();
  const { convParams, setSpecificParam } = useProjectParams();

  const handleMinimize = () => {
    IFrameHelper.sendMessage({
      event: "minimizeChat",
    });
  };

  const endSession = async () => {
    let user_id = convParams?.user_id;
    let project_id = convParams?.project_id;
    let bot_id = convParams?.bot_id;
    let visitor_id = convParams?.visitor_id;
    let session_id =
      localStorage.getItem("session_id") || convParams?.session_id;
    try {
      let response = await modelling_api.post(
        `end_session?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}&session_id=${session_id}&visitor_id=${visitor_id}`
      );
      Cookies.remove("session_id");
      localStorage.removeItem("session_id");
      setSpecificParam("session_id", undefined);
      IFrameHelper.sendMessage({
        event: "reloadChat",
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClose = async (event) => {
    await endSession();
    navigate("/chat");
  };

  return (
    <header className="headerWidget">
      <div className="header_row">
        {/* <div className="msg-icon" onClick={navigateToConv}>
          <i className="fa-solid fa-chevron-left"></i>
        </div> */}

        <div className="initials">
          <Avatar.Group
            maxCount={2}
            maxStyle={
              {
                // color: "#f56a00",
                // backgroundColor: "#fde3cf",
              }
            }
          >
            <Tooltip zIndex="202020202023" title="Meta Bot" placement="top">
              <Avatar
                size="large"
                style={{
                  border: "none",
                  backgroundColor: "rgb(16 38 113)",
                }}
              >
                {getAgentInitials("Meta Bot", true)}
              </Avatar>
            </Tooltip>
          </Avatar.Group>
        </div>

        <div className="header_btns">
          <div className="icons-area">
            <i
              className="fa-solid fa-minus"
              title="Minimize"
              onClick={handleMinimize}
            ></i>

            <Popconfirm
              zIndex="20202020202"
              title={"Are you sure you want to close chat?"}
              okText="Yes"
              cancelText="No"
              onConfirm={handleClose}
            >
              <i className="fas fa-close " title="End chat"></i>
            </Popconfirm>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
